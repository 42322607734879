<template>
  <Headline type="h1" classes="h3 text-center" :text="this.chat.Headline" />
  <Section :elements="this.content" background="mint" id="chat-pre" />
  <Section background="" id="chat">
    <div class="chat flex items-center justify-center flex-col w-full text-center">
      <span v-if="currentLang === 'de'">
        Leider steht der Chat momentan nicht zur Verfügung. Bitte kontaktiere uns über die folgenden Möglichkeiten:
      </span>
      <span v-else>
        Malheureusement, le chat n'est pas disponible pour le moment. Veuillez nous contacter via les moyens suivants:
      </span>
    </div>
  </Section>

  <Section background="creme" id="chat-buttons">
    <ContactGroup :buttons="buttons" headline="" />
  </Section>
</template>

<script>
import {defineAsyncComponent} from "vue";
import eventService from "../../services/eventService";

const Headline = defineAsyncComponent(() => import('@/components/Headline'));
const Section = defineAsyncComponent(() => import('@/layout/Section'));
const ContactGroup = defineAsyncComponent(() => import('@/modules/ContactGroup'));

export default {
  name: "ChatStart",
  data() {
    return {
      currentLang: '',
      content: [
        {
          __component: 'content.page-opener',
          Headline: 'Chat',
          Text: '',
          Image: {},
        }
      ],
      chat: '',
      name: '',
      show_error: false,
      buttons: [
        {
          content: '+352 8002 6002',
          label: 'Hotline',
          type: 'phone',
          url: 'tel:+352 8002 6002'
        },
        {
          content: '+352 621 742 963',
          label: 'Whatsapp',
          type: 'whatsapp',
          url: 'https://wa.me/352621742963?text=Ech%20brauch%20Hëllef002 6002'
        },
        {
          content: '+352 621 742 963',
          label: 'SMS',
          type: 'sms',
          url: 'sms:+352 621742963'
        },
        {
          content: 'peitrusshaus@s-j.lu',
          label: 'Email',
          type: 'email',
          url: 'mailto:peitrusshaus@s-j.lu'
        }
      ]
    }
  },
  components: {
    Headline,
    Section,
    ContactGroup
  },
  methods: {
    getQueryOptions(locale) {
      return {
        populate: {
        },
        sort: [],
        filters: {},
        locale: [locale],
      }
    },
  },
  created() {
    document.title = 'Chat'
    this.currentLang = this.$root.$i18n.locale;
  },
  mounted() {
    eventService.$on('lang-switch', () => {
      this.currentLang = this.$root.$i18n.locale;
    });
  },
}
</script>

<style lang="scss" scoped>
.chat {
  button {
    text-align: center!important;
    justify-content: center!important
  }
  .login-error {
    span {
      color: var(--pumpkin-80-);
    }
  }
}
</style>
